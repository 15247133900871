// most polyfills are imported by Angular
// https://github.com/angular/angular-cli/blob/master/packages/angular_devkit/build_angular/src/angular-cli-files/models/es5-polyfills.js

// let's not import everything and be smart about it
// import 'core-js/es';

// import core-js polyfills
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/set';
import 'core-js/es/string';

// specific web browser additions
import 'core-js/web/dom-collections';
import 'core-js/web/url';

// dev imports
import 'shim-keyboard-event-key';
import 'whatwg-fetch';

// always import this after polyfills are loaded
// don't need 'core-js/es/promise' since Zone has built in promise support
import 'zone.js';
import 'zone.js/dist/zone-patch-rxjs';
